import request from '../request'

//考核人列表
export const judges_index_request = p => {
    return request({
        method:'GET',
        url:'judges/index',
        params: p
    })
}

//新增考核人
export const judges_add_request = d => {
    return request({
        method:'POST',
        url:'judges/add',
        data: d
    })
}

//删除考核人
export const judges_del_request = id => {
    return request({
        method:'DELETE',
        url:'judges/del',
        data: {
            id: id
        }
    })
}

//修改考核人
export const judges_edit_request = d => {
    return request({
        method:'PUT',
        url:'judges/edit',
        data: d
    })
}

//考核人详情
export const judges_details_request = id => {
    return request({
        method:'GET',
        url:'judges/details',
        params: {
            id
        }
    })
}

//获取树形员工（评委）
export const staff_tree_request = () => {
    return request({
        method:'GET',
        url:'judges/staff_tree'
    })
}