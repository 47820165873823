<template>
    <div v-loading.fullscreen.lock="loading">
        <judges-search-bar-component
            add_auth="新增考核人"
            :AllStaff="AllStaff"
            @search="get_judges_index"
            @addTeam="addTeam"
        ></judges-search-bar-component>
        <common-table-component
            details_auth="考核人详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_judges"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_judges_index"
        ></common-page-component>
        <judges-edit-component
            edit_auth="修改考核人"
            del_auth="删除考核人"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :judges_details_data="fjudges_details_data"
            :AllStaff="AllStaff"
            @exitDialog="dialogExit"
            @search="get_judges_index"
            @show_edit="show_edit"
            @details_row="details_judges"
        ></judges-edit-component>
    </div>
</template>

<script>
import { judges_index_request,judges_details_request } from '@/network/kpi/judges.js'
import { staff_list_request } from '@/network/list'

import JudgesSearchBarComponent from '@/components/kpi/judges/JudgesSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import JudgesEditComponent from '@/components/kpi/judges/JudgesEditComponent'

export default {
    name: 'JudgesView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                staff_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'staff_name',
                    label: '考核人',
                    minWidth: '100px',
                },{
                    prop: 'fixed_judges_name',
                    label: '固定评委',
                    minWidth: '100px',
                },{
                    prop: 'judges_num',
                    label: '随机评委个数',
                    minWidth: '120px',
                },{
                    prop: 'judges_arr_name',
                    label: '随机评委库',
                    minWidth: '500px',
                    show_overflow_tooltip: true
                }
            ],
            fjudges_details_data: {},
            AllStaff: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_judges_index(param = {}) {
            this.loading = true
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            judges_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_judges(id) {
            this.fid = id
            this.fdialogFormVisible = true
            judges_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fjudges_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    this.AllStaff = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_judges_index()
        this.get_all_staff()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        JudgesSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        JudgesEditComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>