<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="EditForm"
                    label-width="120px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="考核人" prop="staff_id">
                                    <el-select
                                        v-model="form.staff_id"
                                        filterable
                                        placeholder="选择考核人"
                                        class="staff_idc"
                                        clearable
                                    >
                                        <el-option
                                            v-for="staff in AllStaff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="固定评委" prop="fixed_judges_id">
                                    <el-select
                                        v-model="form.fixed_judges_id"
                                        filterable
                                        placeholder="选择固定评委"
                                        class="fixed_judges_idc"
                                        clearable
                                    >
                                        <el-option
                                            v-for="staff in AllStaff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="随机评委数量" prop="judges_num">
                                    <el-input-number
                                        v-model="form.judges_num"
                                        :min="1"
                                        :step="1"
                                        :precision="0"
                                        :step-strictly="true"
                                        class="judges_numc"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div>
                            <el-input
                                placeholder="随机评委搜索"
                                v-model="JudgesSearch"
                                clearable>
                            </el-input>
                            <div class="down-tree">
                                <el-tree
                                    :data="staff_tree"
                                    show-checkbox
                                    node-key="id"
                                    ref="left_tree"
                                    :filter-node-method="filterNode"
                                    :default-expanded-keys="[]"
                                    :default-checked-keys="form.judges_arr"
                                    :props="defaultProps">
                                </el-tree>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="3"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>考核人</span>
                                </template>
                                <span>{{form.staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>固定评委</span>
                                </template>
                                <span>{{form.fixed_judges_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>随机评委数量</span>
                                </template>
                                <span>{{form.judges_num}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>随机评委库</span>
                                </template>
                                <span>{{form.judges_arr_name}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="JudgesDel('EditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('EditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('EditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('EditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { judges_add_request,judges_del_request,judges_edit_request,staff_tree_request } from '@/network/kpi/judges.js'

import CommonAuthComponent from '@/components/common/CommonAuthComponent'

export default {
    name: 'JudgesEditComponent',
    data() {
        return {
            login_loading: false,
            form: {
                staff_id: '',
                fixed_judges_id: '',
                judges_num: '',
                judges_arr: []
            },
            rules: {
                staff_id: [
                    {
                        required: true,
                        message: '缺少被考核人',
                        trigger: 'blur',
                    },
                ],
                fixed_judges_id: [
                    {
                        required: true,
                        message: '缺少固定评委',
                        trigger: 'blur',
                    },
                ],
                judges_num: [
                    {
                        required: true,
                        message: '请填写随机评委数量',
                        trigger: 'blur',
                    },
                ],
                judges_arr: [
                    {
                        required: true,
                        message: '请选择随机评委',
                        trigger: 'blur',
                    },
                ]
            },
            JudgesSearch: '',
            staff_tree: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '考核人详情'
            } else if (this.show_type === 1) {
                return '添加考核人'
            } else {
                return '修改考核人'
            }
        },
    },
    methods: {
        closeDialog(formName) {
            this.resetForm()
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.JudgesInsert(formName)
            })
        },
        JudgesInsert(formName) {
            this.login_loading = true
            let d = this.form
            d.judges_arr = this.$refs.left_tree.getCheckedKeys().filter( v => v !== undefined)
            judges_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.form = {
                staff_id: '',
                fixed_judges_id: '',
                judges_num: '',
                judges_arr: []
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.JudgesEdit(formName)
            })
        },
        JudgesEdit(formName) {
            this.login_loading = true
            this.form.id = this.id
            let d = this.form
            d.judges_arr = this.$refs.left_tree.getCheckedKeys().filter( v => v !== undefined)
            judges_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.form.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        JudgesDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    judges_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        get_staff_tree() {
            staff_tree_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.staff_tree = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        filterNode(value, data) {
            if (!value) {
                return true
            }
            return data.label.indexOf(value) !== -1;
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        judges_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String,
        AllStaff: {
            type: Array,
            default() {
                return []
            },
        },
    },
    created() {
        this.get_staff_tree()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        JudgesSearch(newVal) {
            this.$refs.left_tree.filter(newVal);
        },
        judges_details_data(newVal) {
            this.form = newVal
        }
    },
}
</script>

<style lang='less'>
.staff_idc,.judges_numc,.fixed_judges_idc {
    width: 100%;
}
</style>